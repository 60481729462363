
import * as s3Runtime$3cxEbzFGLd from '/usr/src/app/client/providers/s3.ts'
import * as github_ugcRuntime$6ZELlRiqMp from '/usr/src/app/client/providers/github_ugc.ts'
import * as vimeoRuntime$IOSVB2GrxU from '/usr/src/app/client/providers/vimeo.ts'
import * as bmcRuntime$IOSVB2GrxU from '/usr/src/app/client/providers/vimeo.ts'
import * as customRuntime$IOSVB2GrxU from '/usr/src/app/client/providers/vimeo.ts'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {
    "hero": {
      "modifiers": {
        "format": "webp",
        "fit": "cover",
        "quality": 100,
        "preload": true
      }
    }
  },
  "provider": "s3",
  "domains": [
    "s3.rottigni.tech",
    "portfolio.rottigni.tech",
    "rottigni.tech",
    "githubusercontent.com",
    "repository-images.githubusercontent.com",
    "img.buymeacoffee.com",
    "localhost",
    "127.0.0.1"
  ],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['s3']: { provider: s3Runtime$3cxEbzFGLd, defaults: {"baseURL":"https://s3.rottigni.tech"} },
  ['github_ugc']: { provider: github_ugcRuntime$6ZELlRiqMp, defaults: {"baseURL":"https://repository-images.githubusercontent.com/"} },
  ['vimeo']: { provider: vimeoRuntime$IOSVB2GrxU, defaults: {"baseURL":"https://i.vimeocdn.com"} },
  ['bmc']: { provider: bmcRuntime$IOSVB2GrxU, defaults: {"baseURL":"https://img.buymeacoffee.com/"} },
  ['custom']: { provider: customRuntime$IOSVB2GrxU, defaults: undefined }
}
        