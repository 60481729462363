
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91article_934RI8btVaM9Meta } from "/usr/src/app/client/pages/assets/articles/[article].vue?macro=true";
import { default as _91_46_46_46doc_93m3bWHfjkjqMeta } from "/usr/src/app/client/pages/assets/docs/[...doc].vue?macro=true";
import { default as indexI7U7HEflSNMeta } from "/usr/src/app/client/pages/assets/index.vue?macro=true";
import { default as _91cert_936LbOFNIGFiMeta } from "/usr/src/app/client/pages/certs/[cert].vue?macro=true";
import { default as indexXR20ISgIaYMeta } from "/usr/src/app/client/pages/certs/index.vue?macro=true";
import { default as contactss9dGnHTaV2Meta } from "/usr/src/app/client/pages/contacts.vue?macro=true";
import { default as cvthm7EKv4FuMeta } from "/usr/src/app/client/pages/cv.vue?macro=true";
import { default as _91repo_93Hnqeh331MsMeta } from "/usr/src/app/client/pages/git/[repo].vue?macro=true";
import { default as indexV1z8KnUIHGMeta } from "/usr/src/app/client/pages/git/index.vue?macro=true";
import { default as indexpvpOBIeCIIMeta } from "/usr/src/app/client/pages/index.vue?macro=true";
import { default as _91media_93FamNTaYdeaMeta } from "/usr/src/app/client/pages/media/[media].vue?macro=true";
import { default as indexVFiIBmDcxrMeta } from "/usr/src/app/client/pages/media/index.vue?macro=true";
import { default as portfoliodusV24i2hRMeta } from "/usr/src/app/client/pages/portfolio.vue?macro=true";
import { default as privacy_45policyNZ36VaUnk1Meta } from "/usr/src/app/client/pages/privacy-policy.vue?macro=true";
import { default as skillsWdv9QK7lMDMeta } from "/usr/src/app/client/pages/skills.vue?macro=true";
import { default as component_45stubuYxk0V0rW5Meta } from "/usr/src/app/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubuYxk0V0rW5 } from "/usr/src/app/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "assets-articles-article___cn",
    path: "/cn/assets/articles/:article()",
    component: () => import("/usr/src/app/client/pages/assets/articles/[article].vue")
  },
  {
    name: "assets-articles-article___es",
    path: "/es/assets/articles/:article()",
    component: () => import("/usr/src/app/client/pages/assets/articles/[article].vue")
  },
  {
    name: "assets-articles-article___fr",
    path: "/fr/assets/articles/:article()",
    component: () => import("/usr/src/app/client/pages/assets/articles/[article].vue")
  },
  {
    name: "assets-articles-article___in",
    path: "/in/assets/articles/:article()",
    component: () => import("/usr/src/app/client/pages/assets/articles/[article].vue")
  },
  {
    name: "assets-articles-article___en",
    path: "/assets/articles/:article()",
    component: () => import("/usr/src/app/client/pages/assets/articles/[article].vue")
  },
  {
    name: "assets-articles-article___it",
    path: "/it/assets/articles/:article()",
    component: () => import("/usr/src/app/client/pages/assets/articles/[article].vue")
  },
  {
    name: "assets-articles-article___jp",
    path: "/jp/assets/articles/:article()",
    component: () => import("/usr/src/app/client/pages/assets/articles/[article].vue")
  },
  {
    name: "assets-articles-article___ru",
    path: "/ru/assets/articles/:article()",
    component: () => import("/usr/src/app/client/pages/assets/articles/[article].vue")
  },
  {
    name: "assets-docs-doc___cn",
    path: "/cn/assets/docs/:doc(.*)*",
    component: () => import("/usr/src/app/client/pages/assets/docs/[...doc].vue")
  },
  {
    name: "assets-docs-doc___es",
    path: "/es/assets/docs/:doc(.*)*",
    component: () => import("/usr/src/app/client/pages/assets/docs/[...doc].vue")
  },
  {
    name: "assets-docs-doc___fr",
    path: "/fr/assets/docs/:doc(.*)*",
    component: () => import("/usr/src/app/client/pages/assets/docs/[...doc].vue")
  },
  {
    name: "assets-docs-doc___in",
    path: "/in/assets/docs/:doc(.*)*",
    component: () => import("/usr/src/app/client/pages/assets/docs/[...doc].vue")
  },
  {
    name: "assets-docs-doc___en",
    path: "/assets/docs/:doc(.*)*",
    component: () => import("/usr/src/app/client/pages/assets/docs/[...doc].vue")
  },
  {
    name: "assets-docs-doc___it",
    path: "/it/assets/docs/:doc(.*)*",
    component: () => import("/usr/src/app/client/pages/assets/docs/[...doc].vue")
  },
  {
    name: "assets-docs-doc___jp",
    path: "/jp/assets/docs/:doc(.*)*",
    component: () => import("/usr/src/app/client/pages/assets/docs/[...doc].vue")
  },
  {
    name: "assets-docs-doc___ru",
    path: "/ru/assets/docs/:doc(.*)*",
    component: () => import("/usr/src/app/client/pages/assets/docs/[...doc].vue")
  },
  {
    name: "assets___cn",
    path: "/cn/assets",
    component: () => import("/usr/src/app/client/pages/assets/index.vue")
  },
  {
    name: "assets___es",
    path: "/es/assets",
    component: () => import("/usr/src/app/client/pages/assets/index.vue")
  },
  {
    name: "assets___fr",
    path: "/fr/assets",
    component: () => import("/usr/src/app/client/pages/assets/index.vue")
  },
  {
    name: "assets___in",
    path: "/in/assets",
    component: () => import("/usr/src/app/client/pages/assets/index.vue")
  },
  {
    name: "assets___en",
    path: "/assets",
    component: () => import("/usr/src/app/client/pages/assets/index.vue")
  },
  {
    name: "assets___it",
    path: "/it/assets",
    component: () => import("/usr/src/app/client/pages/assets/index.vue")
  },
  {
    name: "assets___jp",
    path: "/jp/assets",
    component: () => import("/usr/src/app/client/pages/assets/index.vue")
  },
  {
    name: "assets___ru",
    path: "/ru/assets",
    component: () => import("/usr/src/app/client/pages/assets/index.vue")
  },
  {
    name: "certs-cert___cn",
    path: "/cn/certs/:cert()",
    component: () => import("/usr/src/app/client/pages/certs/[cert].vue")
  },
  {
    name: "certs-cert___es",
    path: "/es/certs/:cert()",
    component: () => import("/usr/src/app/client/pages/certs/[cert].vue")
  },
  {
    name: "certs-cert___fr",
    path: "/fr/certs/:cert()",
    component: () => import("/usr/src/app/client/pages/certs/[cert].vue")
  },
  {
    name: "certs-cert___in",
    path: "/in/certs/:cert()",
    component: () => import("/usr/src/app/client/pages/certs/[cert].vue")
  },
  {
    name: "certs-cert___en",
    path: "/certs/:cert()",
    component: () => import("/usr/src/app/client/pages/certs/[cert].vue")
  },
  {
    name: "certs-cert___it",
    path: "/it/certs/:cert()",
    component: () => import("/usr/src/app/client/pages/certs/[cert].vue")
  },
  {
    name: "certs-cert___jp",
    path: "/jp/certs/:cert()",
    component: () => import("/usr/src/app/client/pages/certs/[cert].vue")
  },
  {
    name: "certs-cert___ru",
    path: "/ru/certs/:cert()",
    component: () => import("/usr/src/app/client/pages/certs/[cert].vue")
  },
  {
    name: "certs___cn",
    path: "/cn/certs",
    component: () => import("/usr/src/app/client/pages/certs/index.vue")
  },
  {
    name: "certs___es",
    path: "/es/certs",
    component: () => import("/usr/src/app/client/pages/certs/index.vue")
  },
  {
    name: "certs___fr",
    path: "/fr/certs",
    component: () => import("/usr/src/app/client/pages/certs/index.vue")
  },
  {
    name: "certs___in",
    path: "/in/certs",
    component: () => import("/usr/src/app/client/pages/certs/index.vue")
  },
  {
    name: "certs___en",
    path: "/certs",
    component: () => import("/usr/src/app/client/pages/certs/index.vue")
  },
  {
    name: "certs___it",
    path: "/it/certs",
    component: () => import("/usr/src/app/client/pages/certs/index.vue")
  },
  {
    name: "certs___jp",
    path: "/jp/certs",
    component: () => import("/usr/src/app/client/pages/certs/index.vue")
  },
  {
    name: "certs___ru",
    path: "/ru/certs",
    component: () => import("/usr/src/app/client/pages/certs/index.vue")
  },
  {
    name: "contacts___cn",
    path: "/cn/contacts",
    component: () => import("/usr/src/app/client/pages/contacts.vue")
  },
  {
    name: "contacts___es",
    path: "/es/contacts",
    component: () => import("/usr/src/app/client/pages/contacts.vue")
  },
  {
    name: "contacts___fr",
    path: "/fr/contacts",
    component: () => import("/usr/src/app/client/pages/contacts.vue")
  },
  {
    name: "contacts___in",
    path: "/in/contacts",
    component: () => import("/usr/src/app/client/pages/contacts.vue")
  },
  {
    name: "contacts___en",
    path: "/contacts",
    component: () => import("/usr/src/app/client/pages/contacts.vue")
  },
  {
    name: "contacts___it",
    path: "/it/contacts",
    component: () => import("/usr/src/app/client/pages/contacts.vue")
  },
  {
    name: "contacts___jp",
    path: "/jp/contacts",
    component: () => import("/usr/src/app/client/pages/contacts.vue")
  },
  {
    name: "contacts___ru",
    path: "/ru/contacts",
    component: () => import("/usr/src/app/client/pages/contacts.vue")
  },
  {
    name: "cv___cn",
    path: "/cn/cv",
    component: () => import("/usr/src/app/client/pages/cv.vue")
  },
  {
    name: "cv___es",
    path: "/es/cv",
    component: () => import("/usr/src/app/client/pages/cv.vue")
  },
  {
    name: "cv___fr",
    path: "/fr/cv",
    component: () => import("/usr/src/app/client/pages/cv.vue")
  },
  {
    name: "cv___in",
    path: "/in/cv",
    component: () => import("/usr/src/app/client/pages/cv.vue")
  },
  {
    name: "cv___en",
    path: "/cv",
    component: () => import("/usr/src/app/client/pages/cv.vue")
  },
  {
    name: "cv___it",
    path: "/it/cv",
    component: () => import("/usr/src/app/client/pages/cv.vue")
  },
  {
    name: "cv___jp",
    path: "/jp/cv",
    component: () => import("/usr/src/app/client/pages/cv.vue")
  },
  {
    name: "cv___ru",
    path: "/ru/cv",
    component: () => import("/usr/src/app/client/pages/cv.vue")
  },
  {
    name: "git-repo___cn",
    path: "/cn/git/:repo()",
    component: () => import("/usr/src/app/client/pages/git/[repo].vue")
  },
  {
    name: "git-repo___es",
    path: "/es/git/:repo()",
    component: () => import("/usr/src/app/client/pages/git/[repo].vue")
  },
  {
    name: "git-repo___fr",
    path: "/fr/git/:repo()",
    component: () => import("/usr/src/app/client/pages/git/[repo].vue")
  },
  {
    name: "git-repo___in",
    path: "/in/git/:repo()",
    component: () => import("/usr/src/app/client/pages/git/[repo].vue")
  },
  {
    name: "git-repo___en",
    path: "/git/:repo()",
    component: () => import("/usr/src/app/client/pages/git/[repo].vue")
  },
  {
    name: "git-repo___it",
    path: "/it/git/:repo()",
    component: () => import("/usr/src/app/client/pages/git/[repo].vue")
  },
  {
    name: "git-repo___jp",
    path: "/jp/git/:repo()",
    component: () => import("/usr/src/app/client/pages/git/[repo].vue")
  },
  {
    name: "git-repo___ru",
    path: "/ru/git/:repo()",
    component: () => import("/usr/src/app/client/pages/git/[repo].vue")
  },
  {
    name: "git___cn",
    path: "/cn/git",
    component: () => import("/usr/src/app/client/pages/git/index.vue")
  },
  {
    name: "git___es",
    path: "/es/git",
    component: () => import("/usr/src/app/client/pages/git/index.vue")
  },
  {
    name: "git___fr",
    path: "/fr/git",
    component: () => import("/usr/src/app/client/pages/git/index.vue")
  },
  {
    name: "git___in",
    path: "/in/git",
    component: () => import("/usr/src/app/client/pages/git/index.vue")
  },
  {
    name: "git___en",
    path: "/git",
    component: () => import("/usr/src/app/client/pages/git/index.vue")
  },
  {
    name: "git___it",
    path: "/it/git",
    component: () => import("/usr/src/app/client/pages/git/index.vue")
  },
  {
    name: "git___jp",
    path: "/jp/git",
    component: () => import("/usr/src/app/client/pages/git/index.vue")
  },
  {
    name: "git___ru",
    path: "/ru/git",
    component: () => import("/usr/src/app/client/pages/git/index.vue")
  },
  {
    name: "index___cn",
    path: "/cn",
    component: () => import("/usr/src/app/client/pages/index.vue")
  },
  {
    name: "index___es",
    path: "/es",
    component: () => import("/usr/src/app/client/pages/index.vue")
  },
  {
    name: "index___fr",
    path: "/fr",
    component: () => import("/usr/src/app/client/pages/index.vue")
  },
  {
    name: "index___in",
    path: "/in",
    component: () => import("/usr/src/app/client/pages/index.vue")
  },
  {
    name: "index___en",
    path: "/",
    component: () => import("/usr/src/app/client/pages/index.vue")
  },
  {
    name: "index___it",
    path: "/it",
    component: () => import("/usr/src/app/client/pages/index.vue")
  },
  {
    name: "index___jp",
    path: "/jp",
    component: () => import("/usr/src/app/client/pages/index.vue")
  },
  {
    name: "index___ru",
    path: "/ru",
    component: () => import("/usr/src/app/client/pages/index.vue")
  },
  {
    name: "media-media___cn",
    path: "/cn/media/:media()",
    component: () => import("/usr/src/app/client/pages/media/[media].vue")
  },
  {
    name: "media-media___es",
    path: "/es/media/:media()",
    component: () => import("/usr/src/app/client/pages/media/[media].vue")
  },
  {
    name: "media-media___fr",
    path: "/fr/media/:media()",
    component: () => import("/usr/src/app/client/pages/media/[media].vue")
  },
  {
    name: "media-media___in",
    path: "/in/media/:media()",
    component: () => import("/usr/src/app/client/pages/media/[media].vue")
  },
  {
    name: "media-media___en",
    path: "/media/:media()",
    component: () => import("/usr/src/app/client/pages/media/[media].vue")
  },
  {
    name: "media-media___it",
    path: "/it/media/:media()",
    component: () => import("/usr/src/app/client/pages/media/[media].vue")
  },
  {
    name: "media-media___jp",
    path: "/jp/media/:media()",
    component: () => import("/usr/src/app/client/pages/media/[media].vue")
  },
  {
    name: "media-media___ru",
    path: "/ru/media/:media()",
    component: () => import("/usr/src/app/client/pages/media/[media].vue")
  },
  {
    name: "media___cn",
    path: "/cn/media",
    component: () => import("/usr/src/app/client/pages/media/index.vue")
  },
  {
    name: "media___es",
    path: "/es/media",
    component: () => import("/usr/src/app/client/pages/media/index.vue")
  },
  {
    name: "media___fr",
    path: "/fr/media",
    component: () => import("/usr/src/app/client/pages/media/index.vue")
  },
  {
    name: "media___in",
    path: "/in/media",
    component: () => import("/usr/src/app/client/pages/media/index.vue")
  },
  {
    name: "media___en",
    path: "/media",
    component: () => import("/usr/src/app/client/pages/media/index.vue")
  },
  {
    name: "media___it",
    path: "/it/media",
    component: () => import("/usr/src/app/client/pages/media/index.vue")
  },
  {
    name: "media___jp",
    path: "/jp/media",
    component: () => import("/usr/src/app/client/pages/media/index.vue")
  },
  {
    name: "media___ru",
    path: "/ru/media",
    component: () => import("/usr/src/app/client/pages/media/index.vue")
  },
  {
    name: "portfolio___cn",
    path: "/cn/portfolio",
    component: () => import("/usr/src/app/client/pages/portfolio.vue")
  },
  {
    name: "portfolio___es",
    path: "/es/portfolio",
    component: () => import("/usr/src/app/client/pages/portfolio.vue")
  },
  {
    name: "portfolio___fr",
    path: "/fr/portfolio",
    component: () => import("/usr/src/app/client/pages/portfolio.vue")
  },
  {
    name: "portfolio___in",
    path: "/in/portfolio",
    component: () => import("/usr/src/app/client/pages/portfolio.vue")
  },
  {
    name: "portfolio___en",
    path: "/portfolio",
    component: () => import("/usr/src/app/client/pages/portfolio.vue")
  },
  {
    name: "portfolio___it",
    path: "/it/portfolio",
    component: () => import("/usr/src/app/client/pages/portfolio.vue")
  },
  {
    name: "portfolio___jp",
    path: "/jp/portfolio",
    component: () => import("/usr/src/app/client/pages/portfolio.vue")
  },
  {
    name: "portfolio___ru",
    path: "/ru/portfolio",
    component: () => import("/usr/src/app/client/pages/portfolio.vue")
  },
  {
    name: "privacy-policy___cn",
    path: "/cn/privacy-policy",
    component: () => import("/usr/src/app/client/pages/privacy-policy.vue")
  },
  {
    name: "privacy-policy___es",
    path: "/es/privacy-policy",
    component: () => import("/usr/src/app/client/pages/privacy-policy.vue")
  },
  {
    name: "privacy-policy___fr",
    path: "/fr/privacy-policy",
    component: () => import("/usr/src/app/client/pages/privacy-policy.vue")
  },
  {
    name: "privacy-policy___in",
    path: "/in/privacy-policy",
    component: () => import("/usr/src/app/client/pages/privacy-policy.vue")
  },
  {
    name: "privacy-policy___en",
    path: "/privacy-policy",
    component: () => import("/usr/src/app/client/pages/privacy-policy.vue")
  },
  {
    name: "privacy-policy___it",
    path: "/it/privacy-policy",
    component: () => import("/usr/src/app/client/pages/privacy-policy.vue")
  },
  {
    name: "privacy-policy___jp",
    path: "/jp/privacy-policy",
    component: () => import("/usr/src/app/client/pages/privacy-policy.vue")
  },
  {
    name: "privacy-policy___ru",
    path: "/ru/privacy-policy",
    component: () => import("/usr/src/app/client/pages/privacy-policy.vue")
  },
  {
    name: "skills___cn",
    path: "/cn/skills",
    component: () => import("/usr/src/app/client/pages/skills.vue")
  },
  {
    name: "skills___es",
    path: "/es/skills",
    component: () => import("/usr/src/app/client/pages/skills.vue")
  },
  {
    name: "skills___fr",
    path: "/fr/skills",
    component: () => import("/usr/src/app/client/pages/skills.vue")
  },
  {
    name: "skills___in",
    path: "/in/skills",
    component: () => import("/usr/src/app/client/pages/skills.vue")
  },
  {
    name: "skills___en",
    path: "/skills",
    component: () => import("/usr/src/app/client/pages/skills.vue")
  },
  {
    name: "skills___it",
    path: "/it/skills",
    component: () => import("/usr/src/app/client/pages/skills.vue")
  },
  {
    name: "skills___jp",
    path: "/jp/skills",
    component: () => import("/usr/src/app/client/pages/skills.vue")
  },
  {
    name: "skills___ru",
    path: "/ru/skills",
    component: () => import("/usr/src/app/client/pages/skills.vue")
  },
  {
    name: component_45stubuYxk0V0rW5Meta?.name,
    path: "/vimeo",
    component: component_45stubuYxk0V0rW5
  },
  {
    name: component_45stubuYxk0V0rW5Meta?.name,
    path: "/vimeo/:pathMatch(.*)",
    component: component_45stubuYxk0V0rW5
  },
  {
    name: component_45stubuYxk0V0rW5Meta?.name,
    path: "/docs",
    component: component_45stubuYxk0V0rW5
  },
  {
    name: component_45stubuYxk0V0rW5Meta?.name,
    path: "/docs/:pathMatch(.*)",
    component: component_45stubuYxk0V0rW5
  }
]