import unhead_KgADcZ0jPj from "/usr/src/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/usr/src/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import debug_hAQZccAnsC from "/usr/src/app/node_modules/nuxt/dist/app/plugins/debug.js";
import payload_client_yVLowv6hDl from "/usr/src/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/usr/src/app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/usr/src/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/usr/src/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import cross_origin_prefetch_client_UzIc2qvBnC from "/usr/src/app/node_modules/nuxt/dist/app/plugins/cross-origin-prefetch.client.js";
import plugin_vue3_YdLad5Mpq3 from "/usr/src/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/usr/src/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/usr/src/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_iGLjBbor9q from "/usr/src/app/node_modules/nuxt-jsonld/dist/runtime/plugin.js";
import switch_locale_path_ssr_5csfIgkrBP from "/usr/src/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import route_locale_detect_VrpP1JaYg8 from "/usr/src/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/route-locale-detect.js";
import i18n_sq1MuCrqbC from "/usr/src/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import unocss_MzCDxu9LMj from "/usr/src/app/.nuxt/unocss.mjs";
import click_outside_rxw4ptE1CP from "/usr/src/app/client/plugins/click-outside.ts";
import dayjs_0prGeUjNDa from "/usr/src/app/client/plugins/dayjs.ts";
import floating_LiWRZbs7sN from "/usr/src/app/client/plugins/floating.ts";
import gtag_PxkxD3Ffck from "/usr/src/app/client/plugins/gtag.ts";
import kinesis_Ekyrnx6ggM from "/usr/src/app/client/plugins/kinesis.ts";
import lord_icon_client_BEKllU4838 from "/usr/src/app/client/plugins/lord-icon.client.ts";
import marquee_yafq7nMViP from "/usr/src/app/client/plugins/marquee.ts";
import mq_BBHBWKH5Gn from "/usr/src/app/client/plugins/mq.ts";
import veevalidate_components_49H18CWUDW from "/usr/src/app/client/plugins/veevalidate-components.ts";
import ssg_detect_3fHkBxLtv0 from "/usr/src/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/ssg-detect.js";
export default [
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  debug_hAQZccAnsC,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  cross_origin_prefetch_client_UzIc2qvBnC,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_iGLjBbor9q,
  switch_locale_path_ssr_5csfIgkrBP,
  route_locale_detect_VrpP1JaYg8,
  i18n_sq1MuCrqbC,
  unocss_MzCDxu9LMj,
  click_outside_rxw4ptE1CP,
  dayjs_0prGeUjNDa,
  floating_LiWRZbs7sN,
  gtag_PxkxD3Ffck,
  kinesis_Ekyrnx6ggM,
  lord_icon_client_BEKllU4838,
  marquee_yafq7nMViP,
  mq_BBHBWKH5Gn,
  veevalidate_components_49H18CWUDW,
  ssg_detect_3fHkBxLtv0
]